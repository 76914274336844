import React, {useState}  from "react";
import {IntlProvider} from "react-intl";
import MessagesEinglish from './../lang/en-US.json';
import MessagesSpanish from './../lang/es-ES.json';

const langContext = React.createContext();

const LangProvider = ({children}) => {

    let localePorDefecto;
	let mensajesPorDefecto;
	const lang = localStorage.getItem('lang');

	if(lang){
		localePorDefecto = lang

		if(lang === 'es-ES'){
			mensajesPorDefecto = MessagesSpanish;
		} else if(lang === 'en-US'){
			mensajesPorDefecto = MessagesEinglish
		} else {
			localePorDefecto = 'es-ES'
			mensajesPorDefecto = MessagesSpanish
		}
	}else {
		localePorDefecto = 'es-ES'
		mensajesPorDefecto = MessagesSpanish
	}

    const [messages, establecerMensajes] = useState(mensajesPorDefecto);
	const [locale, establecerLocale] = useState(localePorDefecto);

    const changeLanguage = (lenguaje) => {
		switch (lenguaje){
			case 'es-ES':
				establecerMensajes(MessagesSpanish);
				establecerLocale('es-ES');
				localStorage.setItem('lang', 'es-ES');
				break;
			case 'en-US':
				establecerMensajes(MessagesEinglish);
				establecerLocale('en-US');
				localStorage.setItem('lang', 'en-US');
				break;
			default:
				establecerMensajes(MessagesSpanish);
				establecerLocale('es-ES');
				localStorage.setItem('lang', 'es-ES');
		}
		window.location.reload(true);
	}

    return (
        <langContext.Provider value={{changeLanguage: changeLanguage}}>
            <IntlProvider locale={locale} messages={messages}>
            {children}
            </IntlProvider>
            
        </langContext.Provider>
    )
    }

export {LangProvider,langContext};