import { FloatingWhatsApp } from 'react-floating-whatsapp'

import yo1 from '../assets/img/yo1.png'

import { FormattedMessage} from 'react-intl';


export default function Service() {

    const props = {
        phoneNumber: '+543585135832',
        accountName: 'Hernán Cabral',
        allowClickAway: true,
        notificationSound: true,
        notificationDelay: 5,
        notification: true,
        avatar: yo1, 
        chatMessage: <FormattedMessage id="app.wp.message" defaultMessage={""} /> ,
        statusMessage: <FormattedMessage id="app.wp.statusMessage" defaultMessage={""} />,
        
      
        
      }

    return (
        <FloatingWhatsApp {...props} />
    )  

}