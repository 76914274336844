import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './router/Router';
import reportWebVitals from './reportWebVitals';
import "semantic-ui-css/semantic.min.css";
import {LangProvider} from './context/langContext'

const root = ReactDOM.createRoot(document.getElementById('root'));
const title = ReactDOM.createRoot(document.getElementById('title'));
title.render(
	process.env.REACT_APP_NAME
)
root.render(
  <React.StrictMode>
    	<LangProvider>
		    <Router />
	    </LangProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
