
import React from 'react';
import TabYears from './tabYears';
import yo from '../assets/img/yo.png';
import { GridRow, GridColumn, Grid, Divider } from 'semantic-ui-react'
import { FormattedMessage} from 'react-intl';

export default function Profile() {
    const owner = process.env.REACT_APP_OWNER;
    const title = process.env.REACT_APP_TITLE;
    return (
        <Grid columns='equal' stackable>
            <GridRow>
                <GridColumn style={{ 
                    /**Para centrar de forma horizontal*/
                    display: 'flex',alignItems: 'center',justifyContent: 'center',}}>
                    <img  src={yo} alt="Hernan Cbral" srcset="" style={{padding: '20px'}} />
                </GridColumn>
                <GridColumn width={11} style={{padding: '25px'}} >
                    <GridRow >
                        <h3>
                              {owner}    
                        </h3>
                        <h4>
                                {title}
                        </h4>
                        <p>
                        <FormattedMessage id="body.profile.description" defaultMessage={""} />
                        </p>
                        <b>
                        <FormattedMessage id="body.profile.phrases" defaultMessage={""} />
                        </b>
                        
                        <Divider
                            as='h4'
                            className='header'
                            horizontal
                                style={{ margin: '3em 0em', textTransform: 'uppercase' }}
                        >  
                        <FormattedMessage id="body.profile.history" defaultMessage={""} />

                        </Divider>
                        
                    </GridRow>

                    <GridRow>
                        <TabYears />
                    </GridRow>

                </GridColumn>

            </GridRow>
        </Grid>
    );
}