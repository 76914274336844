import React from 'react'
import { Accordion, Container } from 'semantic-ui-react'

import { FormattedMessage} from 'react-intl';

const panels = [
  {
    key: 'acquire-dog',
    title: {
      content: (<b> <FormattedMessage id="body.seccion2.question1" defaultMessage={""} /></b>),
      icon: 'question',
    },
    content: {
      content: (
        <span>
          <FormattedMessage id="body.seccion2.response1" defaultMessage={""} />
        </span>
      ),
    },
  },
  {
    key: 'care-for-dogs',
    title: {
      content: ( <b> <FormattedMessage id="body.seccion2.question2" defaultMessage={""} /> </b>),
      icon: 'question',
    },
    content: {
      content: (
        <span>
          <FormattedMessage id="body.seccion2.response2" defaultMessage={""} />
        </span>
      ),
    },
  },
]

const AccordionQuestion = () => (
  <Container>
<Accordion defaultActiveIndex={0} panels={panels} />
  </Container>
  
)

export default AccordionQuestion