/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component} from 'react'
import { InView } from 'react-intersection-observer'
import logo from './assets/img/logo_apis.png';

import {

  Container,
  
  Header,
  Icon,

  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'

import { FormattedMessage} from 'react-intl';
import MenuItems from './components/menuItems';
import FooterItems from './components/footerItems';

import FloatingWhatsapp from './components/floatingWhatsapp';

const name = process.env.REACT_APP_NAME;

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content={<FormattedMessage id="app.welcome" values={{name:name}} defaultMessage={""} />}
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '2em',
        color: '#a3f515',
      }}
    />
    <Header
      as='h2'
      content={<FormattedMessage id="footer.column1.terms" defaultMessage={""} />}
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1em',
      }}
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView })

  render() {
    const { children } = this.props
    const { fixed } = this.state



    return (
      <Media greaterThan='mobile'>
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 250, padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <MenuItems fixed={fixed} />
            </Menu>
            <HomepageHeading />
          </Segment>
        </InView>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <MenuItems fixed={true} />
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 350, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item position='right' href="/">
                    <div className="center" >
                      <img src={logo} style={{
                        width: '65px',
                        borderRadius: '50%',
                        fontSize: '10px',
                        color: '#fff',
                        lineHeight: '50px',
                        textAlign: 'center',
                        background: '#000'
                      }} alt="logo" />
                    </div>
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
    <FloatingWhatsapp/>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer >

    <Segment style={{ padding: '9em 0em' }} vertical>

      <Container text>

      <h1>Términos y Condiciones del Asesoramiento en Software</h1>
      <h3>Estos Términos y Condiciones ("T&C") rigen el acuerdo entre usted ("Cliente") y API's ("Asesor") para la prestación de servicios de asesoramiento en software. Al contratar los servicios del Asesor, usted acepta cumplir con estos Términos y Condiciones.</h3>
      <ol>
        <li>Alcance de los Servicios:
          <p>    El Asesor proporcionará al Cliente asesoramiento experto en las áreas especificadas en el Acuerdo de Servicio ("Acuerdo") firmado por ambas partes. El alcance de los servicios puede incluir, pero no limitarse a:</p>
          <ul>
            <li>Identificación de necesidades y objetivos relacionados con el software.</li>
            <li>Análisis de soluciones de software y selección de las más adecuadas.</li>
            <li>Configuración e implementación de soluciones de software.</li>
            <li>Capacitación sobre el uso de las soluciones de software.</li>
            <li>Identificación y resolución de problemas con las soluciones de software.</li>
            <li>Asesoramiento continuo sobre el uso y optimización de las soluciones de software.</li>
          </ul>
        </li>
        <li>Honorarios y Pagos: Los honorarios por los servicios se establecerán en el Acuerdo y pueden incluir una tarifa fija, tarifas por hora o una combinación de ambas. El pago se realizará de acuerdo con los vocêes del Acuerdo.</li>
        <li>
          Obligaciones del Cliente:
          <p>El Cliente se compromete a:</p>
          <ul>
            <li>Proporcionar al Asesor toda la información relevante y precisa sobre sus necesidades y objetivos.</li>
            <li>Cooperar con el Asesor durante la prestación de los servicios.</li>
            <li>Proporcionar los recursos necesarios para la implementación de las soluciones de software.</li>
            <li>Cumplir con los plazos establecidos en el Acuerdo.</li>
          </ul>
        </li>
        <li>
          Obligaciones del Asesor:
          <p>El Asesor se compromete a:</p>
          <ul>
            <li>Brindar asistencia y asesoramiento en el uso de las soluciones de software.</li>
            <li>Proteger la privacidad de la información del Cliente.</li>
            <li>Proteger la privacidad de la información del Asesor.</li>
            <li>Proteger la privacidad de la información de los otros.</li>
          </ul>
        </li>
        <li>
        Limitación de Responsabilidad:
        El Asesor no se hace responsable de los daños indirectos, incidentales, consecuentes o punitivos que surjan de o estén relacionados con los servicios prestados. La responsabilidad total del Asesor se limitará a los honorarios pagados por el Cliente por los servicios.
        </li>
        <li>
        Confidencialidad:
        El Asesor mantendrá confidencial toda la información del Cliente a la que acceda durante la prestación de los servicios.
        </li>
        <li>
        Resolución de Disputas:
        Cualquier disputa que surja de o esté relacionada con estos Términos y Condiciones se resolverá mediante [método de resolución de disputas, p. ej., arbitraje, mediación].
        </li>
        <li>
        Ley Aplicable:
        Estos Términos y Condiciones se regirán e interpretarán de conformidad con las leyes de [jurisdicción aplicable].
        </li>
        <li>
        Terminación:
        Estos Términos y Condiciones pueden ser rescindidos por cualquiera de las partes por incumplimiento o por conveniencia con previo aviso por escrito.
        </li>
        <li>
        Cancelación:
        Estos Términos y Condiciones pueden ser cancelados por cualquiera de las partes por incumplimiento o por conveniencia con previo aviso por escrito.
        </li>
        <li>
        Cláusulas Generales:
        Estos Términos y Condiciones constituyen el acuerdo completo entre las partes y sustituyen todos los acuerdos o entendimientos anteriores o contemporáneos. Ninguna modificación de estos Términos y Condiciones será válida a menos que se haga por escrito y esté firmada por ambas partes.
        </li>
        <li>
        Renuncia:
        Ninguna renuncia a una disposición de estos Términos y Condiciones se considerará una renuncia a cualquier otra disposición.
        </li>
        <li>
        Divisibilidad:
        Si alguna disposición de estos Términos y Condiciones se considera inválida o inaplicable, dicha disposición se separará de estos Términos y Condiciones y no afectará la validez o aplicabilidad de las disposiciones restantes.
        </li>
        <li>
        Encabezados:
        Los encabezados utilizados en estos Términos y Condiciones se incluyen únicamente para fines de referencia y no afectarán la interpretación de estos Términos y Condiciones.
        </li>
        <li>
        Notificaciones:
        Todas las notificaciones a virtud de estos Términos y Condiciones se harán por escrito y se considerarán debidamente entregadas cuando se reciban personalmente, se envíen por correo certificado o de primera clase con franqueo prepagado, o se transmitan por fax o correo electrónico a las direcciones proporcionadas por las partes en el Acuerdo.
        </li>
        <li>
        Leyes Vigentes:
        Estos Términos y Condiciones se regirán e interpretarán de conformidad con las leyes de [jurisdicción aplicable].
        </li>
      </ol>



      
      </Container>  
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <FooterItems />
    </Segment>
  </ResponsiveContainer>
)

export default HomepageLayout