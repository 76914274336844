import { useEffect, useState } from "react";
import { Checkbox } from "semantic-ui-react";

import {
  enable ,
  disable ,
  setFetchMethod,
} from "darkreader";

import "./DarkToggle.css";

export default function DarkToggle()  {
  const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(false);
  const enableDarkMode = localStorage.getItem('DarkMode');


  const handlePreferredColorSchemeChange = () => {
    setIsDarkModeEnabled(!isDarkModeEnabled);
    localStorage.setItem('DarkMode', !isDarkModeEnabled);
  };

  setFetchMethod(window.fetch);

  useEffect(() => {
    if (enableDarkMode === 'true') {
      setIsDarkModeEnabled(true);
    }else{
      setIsDarkModeEnabled(false);
    }

    if (isDarkModeEnabled) {
      enable();
    } else {
      disable();
    }
  }, [isDarkModeEnabled]);

  return (
    <Checkbox
      toggle
      className="modeSwitchToggle"
      onChange={handlePreferredColorSchemeChange}
      checked={isDarkModeEnabled}
      style={{ margin: "1em" }}
    />
  );
};
