import React from 'react'
import { Link } from "react-router-dom";
import {
    Container,
    Grid,
    Header,
    List,
    Icon,
} from 'semantic-ui-react'
import { FormattedMessage, FormattedDate } from 'react-intl';


export default function FooterItems() {
    const ln = process.env.REACT_APP_LN;
    return (

        <Container>
            <Grid divided inverted stackable>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header inverted as='h4' content={<FormattedMessage id="footer.column1.about" defaultMessage={""} />} />
                        <List link inverted>
                            <List.Item as='a' href="/profile"><FormattedMessage id="footer.column1.profile" defaultMessage={""} /></List.Item>
                            <List.Item as='a' href="/contact"><FormattedMessage id="footer.column1.contact" defaultMessage={""} /></List.Item>
                            <List.Item as='a' href="/termsConditions"><FormattedMessage id="footer.column1.terms" defaultMessage={""} /></List.Item>
                            <List.Item as='a' href="/privacyPolicy"><FormattedMessage id="footer.column1.privacyPolicy" defaultMessage={""} /></List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Header inverted as='h4' content={<FormattedMessage id="footer.column2.services" defaultMessage={""} />} />
                        <List link inverted>
                            <List.Item as='a' href="/services"><FormattedMessage id="footer.column2.advice" defaultMessage={""} /></List.Item>
                            <List.Item as='a' href="/services"><FormattedMessage id="footer.column2.adviceBudget" defaultMessage={""} /></List.Item>
                            <List.Item as='a' href="/projects"><FormattedMessage id="footer.column2.projects" defaultMessage={""} /></List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Header as='h4' inverted>
                            {<FormattedMessage id="footer.column3.information" defaultMessage={""} />}
                        </Header>
                        <List link inverted>
                            <List.Item as='a'>
                                <FormattedMessage id="footer.column3.cv" defaultMessage={""} 
                                    values={{
                                        'link-to-linkedin': (...chunks) => (
                                            <a href={ln} target="_blank"><Icon name='linkedin' style={{ color: '#fff' }}/>{chunks}</a>
                                        )
                                    }}
                                />
                            </List.Item>
                        </List>
                        <p>
                            <FormattedMessage id="footer.column3.copyright" defaultMessage={"Copyright © {date}. All rights reserved."} values={
                                {
                                    date: <FormattedDate value={new Date()} year='numeric' month='long' day='numeric' />,
                                }
                            } />
                        </p>



                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>

    );
}