import React from 'react'
import {
    CardMeta,
    CardHeader,
    CardGroup,

    CardContent,
    Button,
    Icon,
    Card,

    Grid,
    List,
    ListItem,
} from 'semantic-ui-react'

import { FormattedMessage} from 'react-intl';
import AccordionQuestion from './accordionQuestion';




export default function Service(fixed) {
    const adviceUSD = process.env.REACT_APP_SECCION2_PRICE_COL1;
    const adviceBudgetUSD = process.env.REACT_APP_SECCION2_PRICE_COL2;
    const [dolar, setDolar] = React.useState(1);
    const [dolarCard, setDolarCard] = React.useState(1);
    const [advice, setAdvice] = React.useState(0);
    const [adviceBudget, setAdviceBudget] = React.useState(0);
    const intMonth = process.env.REACT_APP_SECCION2_INT_MONTH; 


    async function tarjeta() {
        fetch("https://dolarapi.com/v1/dolares/tarjeta")
            .then(response => response.json())
            .then(data => setDolarCard(data.venta));
    }

    async function oficial() {
        fetch("https://dolarapi.com/v1/dolares/oficial")
            .then(response => response.json())
            .then(data => setDolar(data.venta));
    }

    async function getPrice() {
        await tarjeta();
        await oficial();
        const lang = localStorage.getItem('lang');

        if (lang === 'es-ES') {

            setAdvice(Math.round(adviceUSD * ((dolarCard + dolar)/ 2) ));
            setAdviceBudget(Math.round(adviceBudgetUSD * ((dolarCard + dolar)/ 2) * intMonth ));


        } else if (lang === 'en-US') {
            setAdvice(adviceUSD);
            setAdviceBudget(adviceBudgetUSD);

        } else{
            setAdvice(Math.round(adviceUSD * ((dolarCard + dolar)/ 2) ));
            setAdviceBudget(Math.round(adviceBudgetUSD * ((dolarCard + dolar)/ 2) * intMonth ));
        }
    }


    getPrice();

    return (
        <Grid columns='equal' stackable>
            <Grid.Row textAlign='center'>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

                    <Card centered style={{ width: '80%' }} >
                        <CardContent header={<FormattedMessage id="body.seccion2.column1.title" defaultMessage={""} />} />
                        <FormattedMessage id="body.seccion2.column1.subtitle" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={""} />
                        <CardContent textAlign='left'>
                            <List bulleted>
                                <ListItem>
                                    <FormattedMessage id="body.seccion2.column1.description1" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={"Advice"} />
                                </ListItem>
                                <ListItem>
                                    <FormattedMessage id="body.seccion2.column1.description2" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={"Advice"} />
                                </ListItem>
                                <ListItem>
                                    <FormattedMessage id="body.seccion2.column1.description3" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={"Advice"} />
                                </ListItem>
                                <ListItem>
                                    <FormattedMessage id="body.seccion2.column1.description4" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={"Advice"} />
                                </ListItem>
                            </List>
                        </CardContent>
                        <CardContent extra style={{ fontSize: '1.5em' }}>
                            <Icon name='dollar sign' /> {advice} <FormattedMessage id="body.seccion2.column1.price" defaultMessage={""} />
                        </CardContent>
                    </Card>

                </Grid.Column>
                <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                    <Card centered style={{ width: '80%' }} >
                        <CardContent header={<FormattedMessage id="body.seccion2.column2.title" defaultMessage={""} />} />
                        <FormattedMessage id="body.seccion2.column2.subtitle" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }}  defaultMessage={""} />
                        <CardContent textAlign='left'>
                            <List bulleted>
                                <ListItem>
                                   <FormattedMessage id="body.seccion2.column2.description1" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={"Advice"} />
                                </ListItem>
                                <ListItem>
                                    <FormattedMessage id="body.seccion2.column2.description2" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={"Advice"} />
                                </ListItem>
                                <ListItem>
                                     <FormattedMessage id="body.seccion2.column2.description3" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={"Advice"} />
                                </ListItem>
                                <ListItem>
                                    <FormattedMessage id="body.seccion2.column2.description4" values={{ 'b': (...chunks) => (
                                            <b >{chunks}</b>
                                        ) }} defaultMessage={"Advice"} />
                                </ListItem>
                            </List>
                        </CardContent>
                        
                        <CardContent extra style={{ fontSize: '1.5em' }} >
                            <Icon name='dollar sign' />{adviceBudget} <FormattedMessage id="body.seccion2.column2.price" defaultMessage={""} />
                        </CardContent>
                    </Card>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign='center'>
            <Grid.Column style={{ paddingBottom: '3em', paddingTop: '1em'}}>
            <AccordionQuestion />
                </Grid.Column>
            
            </Grid.Row>
            
        </Grid>
    );
}
