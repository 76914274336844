import { Container, Grid, GridColumn, GridRow, Menu, MenuItem, Icon } from "semantic-ui-react";
import { FormattedMessage } from 'react-intl';


export default function Contact() {
    const phone = process.env.REACT_APP_PHONE;
    const email = process.env.REACT_APP_EMAIL;
    const email1 = process.env.REACT_APP_EMAIL1;
    const address = process.env.REACT_APP_ADDRESS;
    const ln = process.env.REACT_APP_LN;
    const fb = process.env.REACT_APP_FACEBOOK;
    const ins = process.env.REACT_APP_INSTAGRAM;
    const x = process.env.REACT_APP_X;

    return (
        <Container>
            <Grid columns='equal' stackable>
                <GridRow>
                    <GridColumn >
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3340.3178606622437!2d-64.35515111183163!3d-33.153281105202886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d1ffef9701423d%3A0x3546b929ab932b79!2sC.%20Fernando%20Fader%20413%2C%20R%C3%ADo%20Cuarto%2C%20C%C3%B3rdoba!5e0!3m2!1ses-419!2sar!4v1709000415913!5m2!1ses-419!2sar" style={{ border: 0, width: '100%', height: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </GridColumn>
                    <GridColumn >
                        <GridRow>
                            <FormattedMessage id="body.contact.phone" defaultMessage={""} /> <b>{phone}</b>
                        </GridRow>
                        <GridRow>
                            <FormattedMessage id="body.contact.email" defaultMessage={""} /> <b>{email}</b>
                        </GridRow>
                        <GridRow>
                            <FormattedMessage id="body.contact.email" defaultMessage={""} /> <b>{email1}</b>
                        </GridRow>
                        <GridRow>
                            <FormattedMessage id="body.contact.address" defaultMessage={""} />  <b>{address}</b>
                        </GridRow>
                        <br />
                        <GridRow>
                            <Menu icon compact>
                                <MenuItem
                                    as='a'
                                    href={ln}
                                    name='linkedin'
                                >
                                    <Icon name='linkedin' />
                                </MenuItem>
                                <MenuItem
                                    name='twitter'
                                    as='a'
                                    href={x}
                                >
                                    <Icon name='twitter' />
                                </MenuItem>
                                <MenuItem
                                    as='a'
                                    href={ins}
                                    name='instagram'
                                >
                                    <Icon name='instagram' />
                                </MenuItem>
                                <MenuItem
                                    name='facebook'
                                    as='a'
                                    href={fb}
                                >
                                    <Icon name='facebook' />
                                </MenuItem>

                            </Menu>
                        </GridRow>
                    </GridColumn>
                </GridRow>
            </Grid>
        </Container>
    );
}