import React from 'react'
import { TabPane, Tab, Container } from 'semantic-ui-react'
import arrayJobs from '../json/jobs.json'
import { FormattedMessage} from 'react-intl';

const lang = localStorage.getItem('lang');

const panes = arrayJobs.map((job) => {

    return { menuItem: job.year, render: () => <TabPane>
        <Container>
        <h2>{job.position}</h2>
        <a href={job.url} target="_blank" rel="noopener noreferrer"><h3>{job.business}</h3></a>
        {job.business1 ? <a href={job.url1} target="_blank" rel="noopener noreferrer" >
            <u>
            <i><h4>{job.business1}</h4></i>
            </u>
            </a> : null}
        { lang === 'es-ES' ? 
            
            <div>
                <b>{job.es.location}</b>
                <p>{job.es.description}</p>
            </div>
            
        : 
            <div>

                <b>{job.en.location}</b>
                <p>{job.en.description}</p>

            </div>}
            <h5><FormattedMessage id="body.profile.skills" defaultMessage={""} />:</h5>
            <ul>
            {job.skills.map((skill) => <li>{skill}</li>)}  
            </ul>
            </Container>

          
        </TabPane> }
})

const TabExampleVerticalTabular = () => (
  <Tab menu={{ fluid: true, vertical: true, tabular: true}} panes={panes} />
)

export default TabExampleVerticalTabular
