import React from 'react'
import {
  CardMeta,
  CardHeader,
  CardGroup,
  CardDescription,
  CardContent,
  Container,
  Header,
  Icon,
  Card,
  Image,
} from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl';
import projects from '../json/projects.json';



export default function CardProjectGroup(fixed) {

  const [dolar, setDolar] = React.useState(1);
  const [dolarCard, setDolarCard] = React.useState(1);


  async function tarjeta() {
      fetch("https://dolarapi.com/v1/dolares/tarjeta")
          .then(response => response.json())
          .then(data => setDolarCard(data.venta));
  }

  async function oficial() {
      fetch("https://dolarapi.com/v1/dolares/oficial")
          .then(response => response.json())
          .then(data => setDolar(data.venta));
  }

  const lang = localStorage.getItem('lang');

  async function getPrice() {

      await tarjeta();
      await oficial();

  }

  const array1 = Object.values(projects);


  const array = array1[0];

  const wpNumber = process.env.REACT_APP_BUTTOM_PHONE;
  const text = lang === 'es-ES' ? 'Estoy%20interesado%20me%20podian%20enviar%20mas%20informacion?' : 'I%20am%20interested%20in%20more%20information%20on%20you';
  const refWp = "https://wa.me/"+wpNumber+"?&text="+text;

  getPrice();

  return (
    array.length > 0 ?
  <CardGroup centered >
    {array.map(element => {
      const id_titel = "body.seccion4.card"+element.id+".title";
      const id_subtitle = "body.seccion4.card"+element.id+".subtitle";
      const id_description = "body.seccion4.card"+element.id+".description";
      const id_price = "body.seccion4.card.price";
      const id_link = "body.seccion4.card"+element.id+".link";
      const id_month = "body.seccion4.card.month";
      const id_year = "body.seccion4.card.year";
      const image =process.env.PUBLIC_URL+'/img/'+element.image;
      const id_singlePayment = "body.seccion4.card.singlePayment";
      const id_maintenance = "body.seccion4.card.maintenance";
      const singlePayment = element.singlePayment ? true : false;

      return (
        <Card>

        <img src={image} wrapped ui={false} />
        <CardContent>
          <CardHeader><FormattedMessage id={id_titel} defaultMessage={""} /></CardHeader>
          <CardMeta><FormattedMessage id={id_subtitle} defaultMessage={""} /></CardMeta>
          <CardDescription>
            <FormattedMessage id={id_description} defaultMessage={""} />
            {element.price > 0 ? <div>
            <hr />
            {singlePayment ? 
            <div>
            <h4><FormattedMessage id={id_maintenance} defaultMessage={""}/>
            <Icon name='dollar sign' />{lang === 'es-ES' ? Math.round(element.price* ((dolarCard + dolar) / 2)): element.price}<FormattedMessage id={id_price} defaultMessage={""} /></h4>
            <h4><FormattedMessage id={id_singlePayment} defaultMessage={""}/>
            <Icon name='dollar sign' />{lang === 'es-ES' ? Math.round(element.price* ((dolarCard + dolar) / 2) * element.year): Math.round(element.price * element.year)}<FormattedMessage id={id_price} defaultMessage={""} /> </h4>
            </div>
            :
            <div>
            <h4><FormattedMessage id={id_month} defaultMessage={""}/>
            <Icon name='dollar sign' />{lang === 'es-ES' ? Math.round(element.price* ((dolarCard + dolar) / 2)): element.price}<FormattedMessage id={id_price} defaultMessage={""} /></h4>
            <h4><FormattedMessage id={id_year} defaultMessage={""}/>
            <Icon name='dollar sign' />{lang === 'es-ES' ? Math.round(element.price* ((dolarCard + dolar) / 2) * element.year): Math.round(element.price * element.year)}<FormattedMessage id={id_price} defaultMessage={""} /> </h4>
            </div>
            
            }
            </div>
            : null}
            
          </CardDescription>
        </CardContent>
        <CardContent extra>
          {element.URL ? <a>
            <Icon name='world' />
            <FormattedMessage id={id_link} values={
              {
                'link-to-link': (...chunks) => (
                  <a href={element.URL} target="_blank">{chunks}</a>
                )
              }
            } defaultMessage={""} />
          </a> : 
            <a href={refWp} target="_blank">
              <Icon name='whatsapp' />
              <FormattedMessage id={id_link} values={
                {
                  'link-to-link': (...chunks) => (
                    <a href={refWp} target="_blank">{chunks}</a>
                  )
                }
              } defaultMessage={""} />
            </a>
          
          
          
          }

        </CardContent>
      </Card>
      )
         
    })}

  </CardGroup>
  :null

    );
  }
