import React, { useContext } from 'react'
import logo from '../assets/img/logo_apis.png';
import {
    Button,
    Container,
    Menu,
    ButtonGroup,

} from 'semantic-ui-react'
import { Flag } from 'semantic-ui-react';
import { FormattedMessage} from 'react-intl';
import DarkToggle from "../DarkToggle";
import { langContext } from "../context/langContext";

export default function MenuItems(fixed) {
    const lang = useContext(langContext);
    const countryARG = { name: 'Argentina', countryCode: 'ar' }
    const countryUS = { name: 'United States', countryCode: 'us', alias: 'America' }

    const login_bool = process.env.REACT_APP_LOGIN === 'true' ? true : false;
    const signup_bool = process.env.REACT_APP_SIGNUP === 'true' ? true : false;
    const translate_bool = process.env.REACT_APP_TRANSLATE === 'true' ? true : false;


    return (

        <Container>

            <Menu.Item as='a' href="/">
                <div className="center" >
                    <img src={logo} style={{
                        width: '75px',
                        borderRadius: '50%',
                        fontSize: '10px',
                        color: '#fff',
                        lineHeight: '50px',
                        textAlign: 'center',
                        background: '#000'
                    }} alt="logo" />
                </div>

            </Menu.Item>

            <Menu.Item as='a' href="/work"><FormattedMessage id="header.work.map" defaultMessage={""} /></Menu.Item>
            <Menu.Item as='a' href="/services"><FormattedMessage id="header.services" defaultMessage={""} /></Menu.Item>
            <Menu.Item as='a' href="/summary"><FormattedMessage id="header.summary" defaultMessage={""} /></Menu.Item>

            <Menu.Item position='right' >
                <Menu.Item><DarkToggle inverted={!fixed} /></Menu.Item>
                {translate_bool ? <ButtonGroup >
                    <Button onClick={() => lang.changeLanguage('es-ES')} inverted={!fixed} style={{ marginLeft: '1em' }} ><Flag name={countryARG.countryCode} /></Button>
                    <Button onClick={() => lang.changeLanguage('en-US')} inverted={!fixed} ><Flag name={countryUS.countryCode} /> </Button>
                </ButtonGroup> : null}

            </Menu.Item>
            {
                signup_bool || login_bool ? <Menu.Item  style={{marginTop:"1.2em", marginBottom:"1.2em" }}>
                <ButtonGroup >
                    {login_bool ? <Button as='a' inverted={!fixed} style={{ marginLeft: '0.5em' }}>
                        <FormattedMessage id="header.login" defaultMessage={""} />
                    </Button> : null}
                    {signup_bool ? <Button as='a' inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }}>
                        <FormattedMessage id="header.signup" defaultMessage={""} />
                    </Button> : null}
                </ButtonGroup>
            </Menu.Item> : null
            }
            
        </Container>

    );
}