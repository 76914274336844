import {
    Button,
    Container,
    Divider,
    Header,
    Transition,
    
  
  } from 'semantic-ui-react'
import { FormattedMessage} from 'react-intl';
import { useState } from 'react';
export default function Service(fixed) {
  const [visible,setVisible] = useState(false);
  const [visible2,setVisible2] = useState(false);

  function toggleVisibility() {
   setVisible(!visible)
  }

  function toggleVisibility2() {
    setVisible2(!visible2)
   }

  const wpNumber = process.env.REACT_APP_BUTTOM_PHONE;


  const lang = localStorage.getItem('lang');

  const text = lang === 'es-ES' ? 'Estoy%20interesado%20me%20podian%20enviar%20mas%20informacion?' : 'I%20am%20interested%20in%20more%20information%20on%20you';
  
  const refWp = "https://wa.me/"+wpNumber+"?&text="+text;


    return (
        <Container text>
        <Header as='h2' style={{ fontSize: '2em' }}>
        <FormattedMessage id="body.seccion3.row1.title" defaultMessage={""} />
        </Header>
        <Header as='h3' style={{ fontSize: '2em' }}>
        <FormattedMessage id="body.seccion3.row1.subtitle" defaultMessage={""} />
        </Header>
        <p style={{ fontSize: '1.33em' }}>
        <FormattedMessage id="body.seccion3.row1.description1" defaultMessage={""} />
        </p>
        <Transition visible={visible} animation='scale' duration={500}>
        <p style={{ fontSize: '1.33em' }}>
        <FormattedMessage id="body.seccion3.row1.description2" defaultMessage={""} />
        </p>
        </Transition>

        <Button as='a' size='large' onClick={toggleVisibility}>
        <FormattedMessage id="body.seccion3.row1.button" defaultMessage={""} />
        </Button>

        <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >  
          <a href={refWp}><FormattedMessage id="body.seccion3.row2.title" defaultMessage={""} /></a>

        </Divider>

        <Header as='h3' style={{ fontSize: '2em' }}>
        <FormattedMessage id="body.seccion3.row3.title" defaultMessage={""} />
        </Header>
        <p style={{ fontSize: '1.33em' }}>
        <FormattedMessage id="body.seccion3.row3.description1" defaultMessage={""} />
        </p>
        <Button as='a' size='large' onClick={toggleVisibility2} style={{ marginBottom: '1em' }}>
        <FormattedMessage id="body.seccion3.row3.button" defaultMessage={""} />
        </Button>
        <Transition visible={visible2} animation='slide down' duration={500}>
        <p style={{ fontSize: '1.33em' }}>
        <FormattedMessage id="body.seccion3.row3.description2" defaultMessage={""} />
        </p>
        </Transition>
      </Container>
    )
}