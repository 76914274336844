/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React, { Component} from 'react'
import { InView } from 'react-intersection-observer'
import logo from './assets/img/logo_apis.png';

import {

  Container,

  Header,
  Icon,

  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'

import { FormattedMessage} from 'react-intl';
import MenuItems from './components/menuItems';
import FooterItems from './components/footerItems';

import FloatingWhatsapp from './components/floatingWhatsapp';


const name = process.env.REACT_APP_NAME;

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

/* Heads up!
 * HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled
 * components for such things.
 */
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content={<FormattedMessage id="app.welcome" values={{name:name}} defaultMessage={""} />}
      inverted
      style={{
        fontSize: mobile ? '2em' : '4em',
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '2em',
        color: '#a3f515',
      }}
    />
    <Header
      as='h2'
      content={<FormattedMessage id="footer.column1.privacyPolicy" defaultMessage={""} />}
      inverted
      style={{
        fontSize: mobile ? '1.5em' : '1.7em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1em',
      }}
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
class DesktopContainer extends Component {
  state = {}

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView })

  render() {
    const { children } = this.props
    const { fixed } = this.state



    return (
      <Media greaterThan='mobile'>
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 250, padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <MenuItems fixed={fixed} />
            </Menu>
            <HomepageHeading />
          </Segment>
        </InView>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <MenuItems fixed={true} />
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 350, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item position='right' href="/">
                    <div className="center" >
                      <img src={logo} style={{
                        width: '65px',
                        borderRadius: '50%',
                        fontSize: '10px',
                        color: '#fff',
                        lineHeight: '50px',
                        textAlign: 'center',
                        background: '#000'
                      }} alt="logo" />
                    </div>
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}




const ResponsiveContainer = ({ children }) => (
  /* Heads up!
   * For large applications it may not be best option to put all page into these containers at
   * they will be rendered twice for SSR.
   */
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
    <FloatingWhatsapp/>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const email1 = process.env.REACT_APP_EMAIL1;
const email2 = process.env.REACT_APP_EMAIL2;
const email3 = process.env.REACT_APP_EMAIL3;


const HomepageLayout = () => (
  <ResponsiveContainer >
    <Segment style={{ padding: '9em 0em' }} vertical>
    <Container text>

      <h1>Política de Privacidad para Asesoramiento en Software</h1>
      <h3>API's ("Asesor") se compromete a proteger la privacidad de la información personal que usted nos proporciona. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos su información personal.</h3>
      <ol>
        <li>Información que Recopilamos: 
            Recopilamos información personal sobre usted de diversas maneras, incluyendo:
            Información que usted nos proporciona: Cuando usted se contacta con nosotros para solicitar nuestros servicios, recopilamos información como su nombre, dirección de correo electrónico, número de teléfono, información de la empresa y detalles sobre su proyecto.
            Información de uso del sitio web: Cuando usted visita nuestro sitio web, recopilamos información sobre su dispositivo y su actividad de navegación, como su dirección IP, tipo de navegador, sistema operativo, páginas visitadas y tiempo en el sitio.
        </li>
        <li>Uso de la Información:
            Utilizamos la información personal que recopilamos para:
            Brindarle los servicios de asesoramiento en software que usted solicita.
            Contactarlo con respecto a su proyecto y para proporcionarle información relevante.
            Mejorar nuestros servicios y personalizar su experiencia.
            Cumplir con nuestras obligaciones legales y contractuales.
        </li>
        <li>Compartir la Información:
            No compartimos su información personal con terceros sin su consentimiento, excepto en los siguientes casos:
            Proveedores de servicios: Compartimos su información con terceros que nos ayudan a brindar nuestros servicios, como proveedores de alojamiento web, plataformas de correo electrónico y herramientas de gestión de proyectos.
            Requisitos legales: Podemos compartir su información si lo exige la ley o en respuesta a una orden judicial válida.
        </li>
        <li>Seguridad de la Información:
            Tomamos medidas de seguridad razonables para proteger su información personal contra el acceso no autorizado, uso indebido, divulgación, alteración o destrucción.
        </li>
        <li>Sus Derechos:
            Usted tiene derecho a acceder a su información personal, corregirla, eliminarla o restringir su uso. También puede oponerse al procesamiento de su información personal y solicitar que la transfiramos a otro responsable del tratamiento.
        </li>
        <li>Cookies y Tecnologías Similares:
            Utilizamos cookies y tecnologías similares para recopilar información sobre su actividad de navegación. Puede controlar el uso de cookies en la configuración de su navegador.
        </li>
        <li>Cambios en la Política:
            Podemos actualizar esta Política de Privacidad de vez en cuando. La versión más reciente estará disponible en nuestro sitio web.
        </li>
        <li>Contacto:
            Si tiene alguna pregunta sobre esta Política de Privacidad o sobre cómo tratamos su información personal, puede contactarnos a través de: 
             <strong> {email3} </strong> o por correo electrónico a <strong> {email2}</strong> o <strong> {email1}</strong>..
        </li>
      </ol>

      </Container>

      
    </Segment>
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <FooterItems />
    </Segment>
  </ResponsiveContainer>
)

export default HomepageLayout