import React from "react";
import { Route, BrowserRouter, Routes} from "react-router-dom";

import App from "../App";
import TermsConditions from "../termsConditions";
import PrivacyPolicy from "../privacyPolicy";
import Summary from "../components/summaryApp";
import HowWeWork from "../components/howWeWork";
import ServicesAdvice from "../components/servicesAdvice";
import ProjectsApp from "../components/projectsApp";
import ProfileApp from "../components/profileApp";
import ContactApp from "../components/contactApp";



export default function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>} />
                <Route path="/termsConditions" element={<TermsConditions/>}/>
                <Route path="/privacyPolicy" element={<PrivacyPolicy/>}/>
                <Route path="/summary" element={<Summary/>}/>
                <Route path="/work" element={<HowWeWork/>}/>
                <Route path="/services" element={<ServicesAdvice/>}/>
                <Route path="/Projects" element={<ProjectsApp/>}/>
                <Route path="/profile" element={<ProfileApp/>}/>
                <Route path="/contact" element={<ContactApp/>}/>

            </Routes>
        </BrowserRouter>
    );
}

